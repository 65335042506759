import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';
import { Amplify } from "aws-amplify";
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import App from './App';
import Dashboard from './pages/Dashboard/Dashboard';
import Logout from './pages/Logout/Logout';
import ErrorPage from './pages/ErrorPage/ErrorPage';

import * as serviceWorker from "./serviceWorker";
import awsconfig from "./aws-exports";

import "./index.css";
import AccountConfirmationPage from "./pages/AccountConfirmation";
import ForceLogout from "./pages/ForceLogout/ForceLogout";

import * as Sentry from "@sentry/react";

var Config = require("Config");

let amplifyConfig = {
	...awsconfig,
	Auth: {
		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_PASSWORD_AUTH'
		authenticationFlowType: Config.authenticationFlowType !== undefined ? Config.authenticationFlowType : "USER_PASSWORD_AUTH",
	},
};

// If we have in parameter that means start a PKCE or Implict flow
// We switch the clientId to submit the one from the client website
let queryStringParams = new URLSearchParams(window.location.search);
let clientId = queryStringParams.get("client_id");
if (clientId) {
	// We save the client ID, our Amplify auth will be based on that one
	localStorage.setItem("client-id", clientId);
} else {
	// If there is no client-Id in query, we set back the last one used for login
	// it may be undefined if we never logged in
	clientId = localStorage.getItem("client-id");
}
if (clientId) {
	// We configure the Amplify Auth component in the context of using a client website client-id
	// if no clientId is found (direct login not from a client) the web client id of the broker will be used as default
	amplifyConfig.aws_user_pools_web_client_id = clientId;
}
Amplify.configure(amplifyConfig);



Sentry.init({
	dsn: "https://58fda697bbfc14c3cbd12ec07c021d0c@o4506382416805888.ingest.us.sentry.io/4508693845114880",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	attachStacktrace: true
});


ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router>
				<Switch>
					<ProtectedRoute exact path="/dashboard" component={Dashboard} />
					{/* <ProtectedRoute exact path="/settings" component={Settings} /> */}
					<Route exact path="/verifica-account" component={AccountConfirmationPage} />
					<Route exact path="/logout" component={Logout} />
					<Route exact path="/force-logout" component={ForceLogout} />
					<Route exact path="/" component={App} />
					<Route exact path="/reset-password" component={App} />
					<Route exact path="/signup" component={App} />
					<Route path="/login" component={App} />
					<Route component={ErrorPage} />
				</Switch>
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://create-react-app.dev/docs/making-a-progressive-web-app
serviceWorker.unregister();
